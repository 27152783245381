.selected-item-icon {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.projects-container {
  padding-top: 40px;
}
.projects-grid-button .button-default > * {
  padding: 15px 31px;
  font-size: 18px;
}
.projects-grid-button .button-default .icon {
  font-size: 14px;
}
.vacation-filter {
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}
.vh-row > div {
  width: 50%;
}
.vh-bg {
  background-size: cover;
  background-position: center;
}
.vh-data {
  padding: 80px 55px;
  position: relative;
}
.vh-title {
  line-height: 1;
  font-weight: 500;
}
.vh-icon {
  position: relative;
  z-index: 15;
}
.vh-icon .icon {
  margin-right: 15px;
  filter: brightness(0) invert(1);
  width: 50px;
}
.ph-view-overlay {
  position: absolute;
  bottom: 19%;
  right: 7%;
  z-index: 10;
  width: 90px;
  filter: brightness(0) invert(1);
  opacity: 0.4;
}
.search-filter-selectbox {
  min-width: 200px;
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}
.ps-group .icon {
  font-size: 21px;
  margin-right: 10px;
}
.ps-group input {
  font-size: 18px;
  line-height: 1;
  width: 100%;
}
.projects-search {
  width: 100%;
}
.projects-filter-btn {
  padding: 0 1.5rem;
  cursor: pointer;
}
.projects-filter-btn .icon {
  font-size: 21px;
}
.fil-txt {
  font-size: 21px;
  font-weight: 600;
  margin-left: 10px;
}
.projects-row-bar > div {
  border-left: 1px solid #cecece;
}
.selected-item {
  width: 100%;
}
.selected-item-logo,
.dropdown-item-logo {
  width: 28px;
}
.dropdown-shapes-container {
  min-width: 230px;
  position: relative;
}
.selected-item-header {
  cursor: pointer;
}
.selected-item-title,
.dropdown-shape-row .title {
  font-size: 19px;
  margin: 0 10px;
  font-weight: 600;
}
.selected-item-icon {
  font-weight: bold;
  font-size: 15px;
}
.selected-item-icon.active {
  transform: rotate(180deg);
}
.selected-item {
  padding-left: 15px;
  padding-right: 15px;
}
.dropdown-shapes-list {
  position: absolute;
  top: 101%;
  left: -1px;
  width: 100%;
  background: #fff;
  z-index: 10;
  border: 1px solid #cecece;
  display: none;
}
.projects-row-bar > div {
  padding: 10px 0;
}
.dropdown-shape-row {
  padding: 10px 15px;
  line-height: 1;
  border-bottom: 1px solid #cecece;
}
.dropdown-shape-row:last-of-type {
  border: none;
}
.dropdown-shape-row:hover {
  background: #eee;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .projects-row-bar {
    flex-wrap: wrap;
  }
  .vacation-content {
    padding: 0;
  }
  .vacation-dropdown {
    width: 100%;
    padding: 9px 0;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0;
  }
  .vacation-dropdown .search-filter-selectbox {
    margin: 0;
  }
  .projects-search {
    border: none;
    border-bottom: 1px solid #cecece;
  }
  .projects-filter-btn {
    padding: 10px 0;
  }
  .vh-bg {
    height: 200px;
  }
  .vh-row > div {
    width: 100%;
  }
  .vh-data {
    padding: 30px 23px;
  }
  .vh-icon .icon {
    width: 37px;
  }
  .vh-title {
    font-size: 21px;
  }
  .ph-view-overlay {
    bottom: 17%;
    right: 7%;
    width: 60px;
  }
  .projects-grid-button .button-default > * {
    padding: 13px 23px;
    font-size: 17px;
  }
  .projects-row-bar > div {
    border-bottom: 1px solid #cecece;
    border-left: none;
  }
  .dropdown-shapes-container {
    min-width: auto;
    width: 100%;
    max-width: 100%;
  }
  .ps-group,
  .projects-filter-btn,
  .selected-item {
    padding: 0 !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .search-filter-selectbox {
    margin: 0;
    min-width: auto !important;
  }
  .vacation-dropdown {
    width: 40% !important;
  }
  .search-filter-selectbox {
    width: 100%;
  }
  .projects-search {
    width: 53%;
  }
  .projects-filter-btn {
    padding: 0 1rem;
  }
  .vh-data {
    padding: 53px 19px;
  }
  .vh-icon .icon {
    font-size: 40px;
  }
  .vh-title {
    font-size: 19px;
  }
  .selected-item-logo,
  .dropdown-item-logo {
    width: 22px;
  }
  .selected-item-title,
  .dropdown-shape-row .title {
    font-size: 17px;
  }
  .selected-item-icon {
    font-size: 12px;
  }
  .dropdown-shapes-container {
    min-width: 200px;
  }
  .ps-group {
    padding: 0 0.8rem !important;
  }
  .ps-group .icon {
    font-size: 19px;
  }
  .ps-group input {
    font-size: 16px;
  }
  .projects-filter-btn .icon {
    font-size: 17px;
  }
  .fil-txt {
    font-size: 18px;
  }
  .projects-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .vh-data {
    padding: 62px 35px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
