/* Global */
body.rtl {
    direction: rtl;
}

    body.rtl .bg-title,
    body.rtl .search-filter-selectbox,
    body.rtl .footer-logo,
    body.rtl .footer-title,
    body.rtl .footer-contact .text,
    body.rtl .follow-text,
    body.rtl .about-text-container,
    body.rtl .person-data,
    body.rtl .about-sidebar,
    body.rtl .contact-text-container,
    body.rtl .contact-text-address-tel,
    body.rtl .contact-directions,
    body.rtl .contact-form,
    body.rtl .text,
    body.rtl .ms-link,
    body.rtl .modal-f-label,
    body.rtl .filter-price-range .title,
    body.rtl .checkbox-text,
    body.rtl .pbd-row,
    body.rtl .tl-card,
    body.rtl .read-more-parent,
    body.rtl .product-card,
    body.rtl .acc-img-text,
    body.rtl .accl-row,
    body.rtl .auth-content,
    body.rtl .selectz-label,
    body.rtl .selectz-row,
    body.rtl .p2-info-row,
    body.rtl .ss-label,
    body.rtl .ss-option,
    body.rtl .news-wrapper,
    body.rtl .application-title,
    body.rtl .connect-nav,
    body.rtl .con-benefit-row {
        text-align: right;
    }

    body.rtl .select-chevron-div {
        right: auto;
        left: 10px;
        transform: translateY(-50%);
    }

    body.rtl .icon-arrow-right {
        display: inline-block;
        transform: rotateZ(180deg);
    }

    body.rtl .icon-arrow-left {
        display: inline-block;
        transform: rotateZ(-180deg);
    }

    body.rtl .icon-search {
        transform: rotateZ(-90deg);
    }

    body.rtl .home-grid-card-data .bg-title .icon {
        margin-left: 0;
        margin-right: 15px;
    }

    body.rtl .button-default .icon-arrow-right {
        margin-right: 10px;
        margin-left: 0;
    }

    body.rtl .button-default .icon-plus {
        margin-right: 9px;
        margin-left: 0;
    }

    body.rtl .icon-telephone {
        transform: rotate(-90deg);
    }

    body.rtl .button-default .icon-search {
        margin-left: 10px !important;
    }

    body.rtl .ph-view-overlay {
        left: 7%;
        right: auto;
    }

    body.rtl .projects-row-bar > div {
        border-left: none;
        border-right: 1px solid #cecece;
    }

    body.rtl .tel-dropdown {
        margin-left: 0;
        margin-right: 10px;
    }

    body.rtl .swiper-pagination-arrows {
        left: 50px;
        right: auto;
    }

    body.rtl .p2-avatar {
        margin-right: 0;
        margin-left: 20px;
    }

    body.rtl .p2-info-row {
        padding-right: 0;
        padding-left: 25px;
    }
    /* Global **/

    /* Navbar */
    body.rtl .nav-icon-btn:last-of-type {
        padding-left: 0;
        padding-right: 13px;
    }

    body.rtl .nav-icon-btn .label {
        margin-right: 0 !important;
    }
    /* Navbar */

    /* Filter */
    body.rtl .search-filter-selectbox {
        padding-right: 0;
        padding-left: 15px;
        border-right: none;
        border-left: 1px solid #626262;
    }

    body.rtl .search-filter-advanced {
        text-align: left;
    }

    body.rtl .filter-title .title {
        margin-left: 0 !important;
        margin-right: 1rem !important;
    }

    body.rtl .mcb-title .icon {
        right: auto;
        left: 0;
    }

    body.rtl .multiple-checkbox-grid {
        padding-right: 23px;
        padding-left: 0;
    }

    body.rtl .mfgd-input-val {
        margin-left: 0.5rem !important;
        margin-right: 0 !important;
    }

    body.rtl .modal-filter-dropdown .select-chevron-div {
        left: 0;
    }
    /* Filter */

    /* Home */
    body.rtl .service-card .sa-data {
        padding-left: 25px;
        padding-right: 0;
    }

    body.rtl .swiper-arrows .arrow-icon {
        transform: rotateZ(90deg);
    }

    body.rtl .swiper-wrapper {
        direction: ltr;
    }
    /* Home */

    /* Footer */
    body.rtl .footer-links {
        text-align: right;
    }

    body.rtl .social-icons a {
        margin-left: 0;
        margin-right: 10px;
    }

    body.rtl .footer-contact-group input {
        border-right: none;
        border-left: 1px solid #fff;
    }

    body.rtl .footer-b-links a {
        margin-right: 15px;
        margin-left: 0;
    }
    /* Footer */

    /* Projects */
    body.rtl .fil-txt {
        margin-right: 10px;
        margin-left: 0;
    }

    body.rtl .ps-group .icon {
        margin-left: 10px;
        margin-right: 0;
    }

    body.rtl .vh-title {
        text-align: right;
    }

    body.rtl .vh-icon .icon {
        margin-left: 15px;
        margin-right: 0;
    }

    body.rtl .vh-data {
        background-position-x: 10%;
    }

    body.rtl .product-card-company {
        flex-direction: row-reverse;
    }
    /* Projects */

    /* Contact */
    body.rtl .contact-text-address-tel > div:first-of-type {
        padding-left: 60px;
        padding-right: 0;
    }

    body.rtl .cform-selecbox {
        margin-left: 13px;
        margin-right: 0;
        padding-left: 5px;
        padding-right: 0;
        border-right: none;
        border-left: 1px solid #898989;
    }
    /* Contact */

    /* Menu */
    body.rtl .ms-icon {
        margin-right: 12px;
        margin-left: 0;
    }

    body.rtl .ms-link:hover .ms-icon {
        transform: translateX(-21px);
    }

    body.rtl .mbg-title {
        padding-right: 13px;
        padding-left: 0;
        text-align: right;
    }
    /* Menu */

    /* View Project */
    body.rtl .pbd-row {
        padding-left: 25px;
        padding-right: 0;
    }

    body.rtl .pdb-date-row {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    body.rtl .dev-widget-info {
        border-left: none;
        border-right: 1px solid #707070;
    }

    body.rtl .ps-arrows button {
        margin-left: 0;
        margin-right: 15px;
    }

    body.rtl .bc-ac-btn {
        margin-left: 0;
        margin-right: 17px;
    }

    body.rtl .breadcrumb-item::before {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    body.rtl .d2-arrow .icon {
        transform: rotate(180deg);
    }

    body.rtl .design-2-avatar {
        margin-right: 0;
        margin-left: 23px;
    }

    body.rtl .acc-img-text {
        padding-left: 0;
        padding-right: 25px;
    }

    body.rtl .pbd-row {
        margin-right: 0;
    }

    body.rtl .modal-tab-container {
        padding-right: 25px;
        padding-left: 0;
    }

    body.rtl .modal-tab-normal-main-image {
        padding-left: 15px;
        padding-right: 0;
    }
    /* View Project */

    /* Articles */
    body.rtl .news-social-icon .icon {
        margin-right: 0;
        margin-left: 13px;
    }

    body.rtl .article-img {
        margin-right: 0;
        margin-left: 20px;
    }
    /* Articles */

    /* Careers */
    body.rtl .careers-text,
    /* body.rtl .careers-text .text, */
    body.rtl .career-text-card,
    body.rtl .career-text-card .bg-title,
    body.rtl .career-list-card .bg-title {
        text-align: right !important;
    }

    body.rtl .careers-socialmedia .social-icon i {
        margin-right: 0;
        margin-left: 5px;
    }

    body.rtl .social-icon span {
        text-align: right;
    }

    body.rtl .career-list {
        padding-left: 0 !important;
        padding-right: 1.5rem !important;
    }

    body.rtl .application-checkbox {
        display: flex;
    }

    body.rtl .apply-checkboxDiv {
        display: flex;
    }

    body.rtl .app-checkbox-card {
        margin-right: 0;
        margin-left: 5px;
    }

    body.rtl .app-fake-checkbox {
        left: auto;
        right: 19px;
    }

    body.rtl .iti.iti--allow-dropdown {
        display: flex;
    }

.cbr-img {
    margin-right: 0;
    margin-left: 15px;
}
/* Careers */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    body.rtl .modal-tab-container {
        padding: 0;
    }

    body.rtl .mss-btn {
        margin-left: 10px !important;
        margin-right: 0 !important;
    }

    body.rtl .nav-btn-select .label {
        margin: 0 0 0 19px;
    }

    body.rtl .nav-btn-select .select-chevron-div {
        left: 3px;
        top: 15px;
    }

    body.rtl .pd-data-text .pbd-row:first-of-type {
        margin-right: 160px;
        margin-left: 0;
    }

    body.rtl .projects-row-bar > div {
        border-left: none;
        border-right: none;
    }
}

@media (max-width: 767.98px) {
    body.rtl .search-filter-advanced {
        text-align: center;
    }

    body.rtl .button-default .icon {
        margin-right: 10px;
        margin-left: 0;
    }

    body.rtl .search-filter-selectbox {
        padding-right: 15px;
        padding-left: 5px;
    }

    body.rtl .msni-row {
        margin-left: 15px;
        margin-right: 0;
    }

    body.rtl .footer-b-links {
        display: flex;
        flex-wrap: wrap;
    }

    body.rtl .p2-info-row {
        padding-right: 0;
    }

    body.rtl .p2-avatar {
        margin-left: 15px;
    }

    body.rtl .careers-socialmedia .social-icon i {
        margin-right: 0;
        margin-left: 0;
    }
}

@media (max-width: 991.98px) {
    body.rtl .d2-image-view {
        margin-right: 0;
        margin-left: 5px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    body.rtl .pbd-row {
        margin-right: 0;
    }

    body.rtl .pd-data-text .pbd-row:first-of-type {
        margin-right: 150px;
        margin-left: 0;
    }

    body.rtl .swiper-pagination-arrows {
        left: 12px;
        right: auto;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    body.rtl .button-default .icon {
        margin-left: 9px !important;
    }

    body.rtl .contact-text-address-tel > div:first-of-type {
        padding-left: 25px;
    }

    body.rtl .pbd-row {
        margin-right: 0;
    }

    body.rtl .pd-data-text .pbd-row:first-of-type {
        margin-left: 0;
        margin-right: 180px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    body.rtl .pbd-row {
        margin-right: 0;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
    body.rtl .pbd-row {
        margin-right: 0;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
