.project-cover-img {
  height: 468px;
  width: 100%;
  object-fit: cover;
}
.project-cover {
  position: relative;
}
.project-profile-outer {
  width: 160px;
  height: 160px;
  position: absolute;
  top: -80px;
  z-index: 10;
  background: #fff;
  padding: 12px;
  border-radius: 16px;
}
.project-profile-inner {
  width: 100%;
  height: 100%;
  border: 1px solid #d6d6d6;
  padding: 10px;
  border-radius: 12px;
}
.project-profile-outer img {
  width: 100%;
  height: 100%;
}
.project-design-structure {
  display: flex;
  justify-content: space-between;
}
.project-design-structure > div:first-of-type {
  width: 17%;
}
.project-design-structure > div:nth-of-type(2) {
  width: 54%;
}
.project-design-structure > div:last-of-type {
  width: 23%;
}
.project-design-structure > div {
  padding: 33px 0 15px;
  border-bottom: 1px solid #e0e0e0;
}
.project-design-structure:last-of-type > div {
  border: none;
}
.pbd-row {
  padding-right: 25px;
  margin-bottom: 20px;
}
.pbd-title {
  color: #747474;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 9px;
}
.pbd-data {
  line-height: 1;
  font-weight: 600;
  font-size: 22px;
  color: #000;
}
.project-bar-details {
}
.small-text {
  color: #747474;
  font-size: 14px;
  font-weight: 600;
}
.pdb-date-row .small-text {
  margin-top: -4px;
}
.project-bar-actions {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 10px;
}
.project-action-btn {
  text-align: center;
}
.project-action-btn .icon {
  font-size: 26px;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}
.project-action-btn .title {
  font-size: 14px;
  line-height: 1.2;
  width: 100%;
}
.project-action-btn:hover {
  opacity: 0.7;
}
.project-action-btn:active {
  opacity: 0.4;
}
.accordion-card-header {
  padding: 0;
}
.card {
  border-bottom: 1px solid #00153c80 !important;
}
.toggle-accordion-btn {
  padding: 13px 0;
  line-height: 1;
  width: 100%;
}
.toggle-accordion-btn .title {
  font-size: 18px;
}
.toggle-accordion-btn .icon {
  font-weight: bold;
  font-size: 15px;
}
.accordion-card-header {
  background: none;
  border: none;
}
.card {
  border: none;
  border-radius: 0;
}
.list-row {
  display: flex;
}
.list-shape {
  position: relative;
  padding-left: 20px;
}
.list-circle {
  position: absolute;
  top: 9px;
  left: 6px;
  width: 7px;
  height: 7px;
  background: #000;
  border-radius: 50%;
}
.card-body {
  padding: 0;
}
.list-text {
  color: #000;
  font-size: 16px;
}
.design-list-container {
  background: #fafafa;
  padding: 5px;
}
.tl-title {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
}
.tl-text {
  font-size: 17px;
  line-height: 1.5;
}
.tl-card {
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 15px;
}
.tl-card:last-of-type {
  border-bottom: none;
  margin-bottom: 15px;
}
.dc-btn {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
  justify-content: center;
}
.dc-btn:active {
  opacity: 0.7;
}
.dc-btn .icon {
  margin: 0 8px;
  font-size: 19px;
}
.ask-btn,
.register-btn {
  background: #02585c;
  padding: 18px;
  color: #fff;
  line-height: 1;
}
.ask-btn:hover {
  background: #024447;
  color: #fff;
}
.ask-btn:active,
.ask-btn:focus {
  color: #fff;
}
.register-btn {
  background: #000;
  position: relative;
}
.register-btn:hover {
  background: #2c2c2c;
}
.developer-widget {
  padding: 50px 0;
}
.design-contact {
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.developer-widget img {
  width: 95px;
  padding: 10px;
}
.developer-widget a {
  border: 1px solid #707070;
}
.dev-widget-info {
  padding: 15px;
  border-left: 1px solid #707070;
}
.dev-widget-info .title {
  font-size: 20px;
  line-height: 1;
}
.dev-link-text {
  font-size: 17px !important;
  line-height: 1.5 !important;
  padding-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
}
.read-more-content {
  font-size: 17px !important;
  line-height: 1.5 !important;
}
.project-contact-form {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 40;
  background: #fff;
  border: 1px solid #898989;
  padding: 19px 16px;
  display: none;
}

.contact-header img {
  width: 100%;
  object-fit: cover;
}
.contact-container {
  padding: 70px 0 150px;
}
.contact-text-container {
  margin-bottom: 100px;
}
.bg-title {
  font-size: 40px;
  margin-bottom: 30px;
}
.contact-text-container .text,
.contact-text-address-tel .text {
  font-size: 21px;
  color: #333;
  line-height: 1.5;
}
.contact-text-address-tel .text {
  color: #000;
}
.contact-text-address-tel > div:first-of-type {
  padding-right: 60px;
}
.contact-direction-btn .icon {
  font-weight: 600;
  font-size: 27px;
  margin: 0 17px;
}
.contact-direction-btn {
  font-size: 22px;
  color: #055bd0;
}
.contact-direction-btn:hover {
  color: #055bd0;
  opacity: 0.7;
}
.contact-direction-btn:active {
  color: #055bd0;
  opacity: 0.4;
}
.contact-direction-btn:focus {
  color: #055bd0;
}
.cform-title {
  margin-bottom: 12px;
}
.cform-title .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
}
.close-contact .icon {
  font-weight: bold;
}
.cform-sub {
  font-size: 17px;
}
.cform-group {
  margin-bottom: 15px;
  border: 1px solid #898989;
  padding: 8px 17px;
}
.cform-group input,
.cform-group textarea {
  width: 100%;
  font-size: 16px;
  resize: none;
  outline: none;
  border-radius: 0 !important;
}
.cform-group textarea {
  border: none;
}
.cform-action .button-default > * {
  width: 100%;
  padding: 15px 26px;
  text-align: center;
  justify-content: center;
  font-size: 20px;
}
.cform-selecbox {
  margin-right: 13px;
  padding-right: 5px;
  border-right: 1px solid #898989;
}
.checkbox-text {
  font-size: 13px !important;
  line-height: 1.3;
  width: calc(100% - 28px);
}
.checkbox-group {
  margin-top: 13px;
}
.modal-tab-container {
  background: #f8f9fb;
  padding: 25px 25px;
  padding-right: 0;
  margin: 30px 0;
}
.modal-tab-flexable {
  display: flex;
}
.modal-tab-actions {
  width: 70px;
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.modal-tab-tel {
  margin: 25px 0;
}
.modal-tab-actions > div {
  width: 100%;
}
.mss-btn-black {
  background: #000 !important;
  color: #fff;
}
.mss-btn-black:hover,
.mss-btn-black:active,
.mss-btn-black:focus {
  background: #000 !important;
  color: #fff;
}
.mss-btn {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-bottom: 13px;
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 9px 7px;
}
.mss-btn .icon {
  font-weight: bold;
  font-size: 23px;
}
.mss-btn .icon-sm {
  font-size: 26px !important;
}
.modal-tab-content {
  width: calc(100% - 70px);
}
.modal-tab-normal-main-image {
  width: 75%;
  height: 550px;
  padding-right: 15px;
}
.modal-tab-normal-main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal-tab-normal-images {
  width: 25%;
}
.modal-tab-overview {
  /* height: 550px; */
  overflow-y: auto;
  scroll-behavior: smooth;
}
.msni-row {
  height: calc(33.3% - 10px);
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0.4;
}
.msni-row:last-of-type {
  margin: 0;
}
.msni-row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .msni-row.unactive {
  opacity: 0.4;
} */
.msni-row.active {
  opacity: 1;
}
.md-video-container {
  width: 100%;
  height: 550px;
  position: relative;
}
.modal-video-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vp-overlay {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s linear;
}
.vp-overlay button {
  width: 90px;
  height: 90px;
}
.md-video-container:hover .vp-overlay {
  opacity: 1;
}

.md-video-container .vp-overlay.active {
  opacity: 1 !important;
}
.modal-tab {
  display: none;
}
.modal-project-tab {
  display: none;
}
.modal-project-tab.active,
.modal-tab.active {
  display: block !important;
}
.wrapper-container.toggle-wrapper-active {
  display: none !important;
}
.md-tab-btn.mss-btn.active {
  background: #000 !important;
  color: #fff !important;
}
.modal-tab-arrows {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 18px 0;
}
.mss-btn-arrow {
  width: 100%;
}
.mss-btn-arrow:hover {
  color: #666;
}
.mss-btn-arrow:active {
  opacity: 0.5;
}
.mss-btn-arrow .icon {
  font-size: 25px;
  font-weight: bold;
}
.fraction-counter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.fraction-counter span {
  width: 100%;
  text-align: center;
  padding: 11px 10px;
  font-size: 20px;
  line-height: 1;
  background: #fff;
}
.fraction-counter span:first-of-type {
  border-bottom: 1px solid #000;
}
.mtaf {
  display: none;
}
.mtaf.active {
  display: block !important;
}
.swiper-wrapper-modal-tab {
  height: 550px;
}

.swiper-wrapper-modal-tab-thumb img {
  height: 550px;
}

.swiper-wrapper-modal-tab .swiper-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .project-cover-img {
    height: 250px;
  }
  .project-profile-outer {
    width: 140px;
    height: 140px;
    top: -65px;
  }
  .project-design-structure {
    flex-wrap: wrap;
  }
  .project-bar-fake-profile {
    width: 100% !important;
    height: 50px !important;
    border: none !important;
    display: none;
  }
  .pd-data-text .pbd-row:first-of-type {
    margin-left: 160px;
  }
  .project-bar-details {
    width: 100% !important;
  }
  .project-bar-details,
  .project-bar-actions {
    border: none !important;
  }
  .project-bar-actions {
    width: 100% !important;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0 !important;
    gap: 0;
    border: 1px solid #e0e0e0 !important;
  }
  .project-action-btn {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border: 1px solid #e0e0e0;
    padding: 11px;
  }
  .project-action-btn .title {
    min-width: 62px;
    width: auto;
    text-align: left;
  }
  .project-action-btn .icon {
    margin: 0;
    width: auto;
    margin: 0 10px;
    min-width: 32px;
    font-size: 22px;
  }

  .pbd-title {
    margin-bottom: 7px;
    font-size: 12px;
  }
  .pbd-data {
    font-size: 19px;
  }
  .pdb-date-row {
    margin-bottom: 0 !important;
    display: none;
  }
  .pbd-row {
    margin-bottom: 25px;
  }
  .project-bar-details {
    flex-wrap: wrap;
  }
  .sidebar-accordion,
  .text-location,
  .about-developer {
    width: 100% !important;
  }
  .project-design-structure > div {
    padding: 21px 0 13px;
  }
  .project-bar-details {
    padding-bottom: 0 !important;
  }
  .dc-btn .icon {
    display: none;
  }
  .modal-tab-container {
    padding: 0;
  }
  .modal-tab-flexable {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .modal-tab-flexable > div {
    width: 100%;
  }
  .modal-tab-actions {
    padding: 13px;
    display: flex;
    flex-wrap: wrap;
  }
  .mss-btn {
    width: auto;
    display: flex;
    align-items: center;
    padding: 9px 7px;
    margin: 0 !important;
    margin-right: 10px !important;
  }
  .modal-tab-arrows-fraction {
    display: none;
  }
  .modal-tab-actions > div {
    width: auto !important;
  }
  .modal-top-actions,
  .modal-tab-tel {
    display: flex;
  }
  .mtaf {
    display: none !important;
  }
  .modal-tab-tel {
    margin: 0;
  }
}

@media (max-width: 767.98px) {
  .vp-overlay {
    height: 100% !important;
  }
  .md-video-container {
    height: auto;
  }
  .ds-modal-tab-flexable {
    flex-wrap: wrap;
  }
  .vp-overlay button {
    width: 60px;
    height: 60px;
  }

  .ds-modal-tab-flexable > div {
    width: 100%;
    height: auto;
  }
  .modal-tab-normal-main-image {
    min-height: 300px;
  }
  .msni-row {
    height: 100px;
  }
  .modal-tab-normal-main-image {
    padding: 0 !important;
  }
  .modal-tab-overview {
    padding-top: 10px;
    height: auto;
  }
  .modal-tab-normal-images {
    overflow-x: auto;
  }
  .swiper-wrapper-modal-tab {
    height: 80px;
  }
  .msni-row {
    margin-bottom: 0;
    margin-right: 15px;
    display: inline-block;
    max-width: 33.333%;
    padding: 0;
    border: 0;
    flex-basis: 33.333%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .msni-row:last-of-type {
    margin-right: 0;
  }
  .modal-project-tab {
    height: 100%;
  }
  .ds-modal-tab-flexable {
    height: 100%;
  }
}

@media (max-width: 1199.98px) {
  .design-contact {
    position: fixed;
    background: #fff;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  .ask-btn {
    margin: 0;
  }
  .dc-btn {
    margin: 0;
    font-size: 14px;
  }
  .dc-contact-container {
    width: 49%;
    position: static !important;
  }
  /* .project-contact-form {
    bottom: 15px;
  } */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .design-contact {
    max-width: 530px;
    left: 50%;
    transform: translateX(-50%);
  }
  .dc-btn {
    margin: 0;
    font-size: 17px;
  }
  .project-design-structure {
    flex-wrap: wrap;
  }
  .project-bar-fake-profile {
    width: 100% !important;
    border: none !important;
    display: none;
  }
  .project-bar-details {
    width: 100% !important;
    padding-top: 23px !important;
    padding-bottom: 0 !important;
  }
  .pbd-row {
    margin-bottom: 21px;
  }
  .pbd-title {
    margin-bottom: 8px;
    font-size: 14px;
  }
  .pbd-data {
    font-size: 20px;
  }
  .project-bar-actions {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    width: 100% !important;
  }
  .project-action-btn .icon {
    font-size: 30px;
  }
  .project-bar-actions {
    padding: 20px 0;
  }
  .sidebar-accordion {
    width: 100% !important;
  }
  .text-location {
    width: 100% !important;
  }
  .about-developer {
    width: 62% !important;
  }
  .developer-widget {
    padding-top: 0;
  }
  .project-contact-form {
    max-width: 510px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .project-bar-actions {
    padding: 20px 0 !important;
  }
  .project-cover-img {
    height: 300px;
  }
  .project-profile-outer {
    width: 130px;
    height: 130px;
    top: -65px;
  }
  .pdb-date-row {
    display: none;
  }
  .pd-data-text .pbd-row:first-of-type {
    margin-left: 150px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .design-contact {
    max-width: 710px;
    left: 50%;
    transform: translateX(-50%);
  }
  .dc-btn {
    margin: 0;
    font-size: 17px;
  }
  .project-design-structure {
    flex-wrap: wrap;
  }
  .project-bar-fake-profile {
    display: none;
  }
  .project-bar-details {
    width: 100% !important;
    padding-bottom: 0 !important;
    display: block !important;
    padding-top: 24px !important;
  }
  .pd-data-text .pbd-row:first-of-type {
    margin-left: 180px;
  }
  .pbd-row {
    margin-bottom: 20px;
  }
  .pbd-title {
    margin-bottom: 10px;
  }
  .project-cover-img {
    height: 330px;
  }
  .project-bar-actions {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    width: 100% !important;
  }
  .project-action-btn .icon {
    font-size: 30px;
  }
  .project-bar-actions {
    padding: 20px 0;
  }
  .sidebar-accordion {
    width: 40% !important;
  }
  .text-location {
    width: 56% !important;
  }
  .about-developer {
    width: 50% !important;
  }
  .developer-widget {
    padding-top: 0;
  }
  .project-contact-form {
    max-width: 510px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .project-bar-actions {
    padding: 20px 0 !important;
  }
  .modal-tab-normal-images {
    width: 100%;
  }
  .modal-tab-normal-main-image {
    padding: 0;
    width: 100%;
    padding-bottom: 15px;
  }
  .ds-modal-tab-flexable {
    flex-wrap: wrap;
  }
  .swiper-wrapper-modal-tab {
    height: 100px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .project-bar-actions {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;
  }
  .project-bar-fake-profile {
    width: 23% !important;
  }
  .sidebar-accordion {
    width: 24% !important;
  }
  .project-design-structure {
    flex-wrap: wrap;
  }
  .text-location {
    width: 72% !important;
  }
  .project-bar-details {
    width: 77% !important;
  }
  .project-bar-actions {
    width: 100% !important;
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .project-design-structure > div {
    padding: 29px 0;
  }
  .about-developer {
    width: 36% !important;
  }
  .pbd-data {
    font-size: 20px;
  }
  .design-contact,
  .d2-actions-header {
    width: 550px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .project-bar-details {
    padding-bottom: 7px !important;
  }
  .project-bar-actions {
    padding: 18px 0 !important;
  }
  .project-cover-img {
    height: 390px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .design-contact {
    position: relative;
  }
  .project-bar-actions {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;
  }
  .project-bar-fake-profile {
    width: 23% !important;
  }
  .sidebar-accordion {
    width: 24% !important;
  }
  .project-design-structure {
    flex-wrap: wrap;
  }
  .text-location {
    width: 72% !important;
  }
  .project-bar-details {
    width: 77% !important;
  }
  .project-bar-actions {
    width: 100% !important;
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .project-design-structure > div {
    padding: 29px 0;
  }
  .about-developer {
    width: 36% !important;
  }
  .project-bar-details {
    padding-bottom: 10px !important;
  }
  .project-bar-actions {
    padding: 20px 0 !important;
  }
  .project-contact-form {
    bottom: 0;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .design-contact {
    position: relative;
  }
}
