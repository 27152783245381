.breadcrumb-item a {
  font-weight: 600;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #b2b2b2;
}
.breadcrumb-item {
  font-weight: 600;
}
.breadcrumb {
  padding: 0.75rem 0;
  border-radius: 0;
  background: none;
  margin: 0;
}
.p2-slideshow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.p2-slide-main-view {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p2-slides-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
.p2-slides-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.d2-image-view {
  cursor: pointer;
  opacity: 0.7;
  height: 170px;
}
.d2-image-view.active {
  opacity: 1;
}
.p2-actions {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.p2-avatar {
  margin-right: 20px;
}
.p2-avatar img {
  width: 100px;
  border: 1px solid #707070;
}
.p2-profile-bar-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding: 30px 0;
}
.p2-info-row-title {
  color: #747474;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 6px;
}
.p2-info-row-date {
  color: #747474;
  font-size: 14px;
  font-weight: 600;
}
.p2-info-row-desc {
  line-height: 1;
  font-weight: 600;
  font-size: 22px;
  color: #000;
}
.p2-profile-left {
  display: flex;
}
.p2-profile-info {
  width: calc(100% - 100px);
  display: flex;
  flex-wrap: wrap;
}
.p2-info-row {
  padding-top: 25px;
  padding-right: 25px;
}
.p2-place-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding-top: 25px;
}
.project-action-btn {
  text-align: center;
  display: block;
}
.project-action-btn .icon {
  font-size: 26px;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}
.p2-accordion-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 40px;
}
.card {
  border: none !important;
}
.toggle-accordion-btn {
  border-bottom: 1px solid #0d0d0d !important;
}
.accordion-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 30px;
}
.accordion-text {
  font-size: 17px !important;
}
.card-body {
  padding: 15px 0 20px;
}
.accl-title {
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 6px;
}
.accl-info {
  color: #00000090;
  font-size: 17px;
}
.acc-img img {
  width: 130px;
  border: 1px solid #707070;
}
.acc-img-text {
  width: calc(100% - 130px);
  padding-left: 25px;
}
.bc-ac-btn {
  margin-left: 17px;
  font-size: 17px;
  line-height: 1;
}
.bc-ac-btn .icon {
  font-weight: normal;
  margin: 0 6px;
  font-size: 17px;
}
.bc-ac-btn .icon-heart {
  font-weight: 600;
}

#modalShare .modal-dialog {
  max-width: 380px;
}
#modalShare .modal-body {
  padding: 0;
}
.ms-header {
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  border-bottom: 1px solid #e0e0e0;
  padding: 14px 15px;
}
.ms-header .icon:before {
  font-weight: bold;
  color: #000;
}
.ms-header > * {
  line-height: 1;
}
.ms-header .title {
  font-size: 22px;
}
.ms-row {
  width: 100%;
  padding: 17px 20px;
  border-bottom: 1px solid #e4e4e4;
}
a.ms-row:last-of-type {
  border: none;
}
.ms-row-title {
  font-weight: 600;
  font-size: 17px;
}
.ms-row .icon {
  font-weight: bold;
  font-size: 24px;
}
.project-contact-form {
  bottom: 0;
}
.project-action-btn .icon {
  margin: 0;
}
.d2-image-view {
  position: relative;
}
.d2-image-view-mi {
  position: relative;
}
.more-imgs {
  background: #38383882;
  width: 100%;
  height: 100%;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .p2-slider-left,
  .p2-slides-grid {
    margin: 0 -15px;
  }
  .p2-place-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
}

@media (max-width: 767.98px) {
  /* Started From Here */
  .p2-accordion-grid,
  .p2-slideshow,
  .p2-profile-bar-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
  .p2-avatar img {
    width: 105px;
  }
  .p2-info-row:first-of-type {
    padding-top: 0;
    width: 100%;
  }
  .p2-info-row {
    padding-top: 22px;
    padding-right: 15px;
  }
  .p2-info-row-title {
    font-size: 12px;
  }
  .p2-info-row-desc {
    font-size: 17px;
  }
  .p2-avatar {
    margin-right: 15px;
  }
  .p2-info-row-date {
    font-size: 10px;
  }
  .project-action-btn .title {
    text-align: center;
  }
  .project-action-btn {
    padding: 0;
    border: none;
  }
  #shcView {
    height: 270px;
  }
  .breadcrumb-container {
    display: none !important;
  }
  .d2-image-view {
    height: 85px;
  }
  .p2-profile-left {
    padding-bottom: 15px;
    margin-bottom: 0;
    border-bottom: 1px solid #707070;
  }
  /* Started From Here */
}

@media (max-width: 991.98px) {
  .d2-image-view {
    display: inline-block;
    max-width: calc(33% - 1.75px);
    padding: 0;
    border: 0;
    flex-basis: calc(33% - 1.75px);
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 5px;
  }
  .d2-image-view:last-of-type {
    margin: 0;
  }
  .p2-slides-grid {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 5px;
  }
  .p2-slides-grid {
    gap: 0;
  }
  .p2-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    background: #fff;
    padding: 15px;
    gap: 5px;
  }
  .p2-slideshow {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
  .p2-profile-left {
    padding-bottom: 25px;
    margin-bottom: 5px;
    border-bottom: 1px solid #707070;
  }
  .p2-profile-bar-grid {
    gap: 0;
  }
}

@media (max-width: 1199.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* Started From Here */
  .p2-place-grid {
    grid-template-columns: repeat(5, 1fr);
  }
  .d2-image-view {
    height: 90px;
  }
  .p2-slideshow {
    padding-top: 20px;
  }
  /* Started From Here */

  .design-2-accordion > div {
    width: 100%;
  }
  .breadcrumb-container {
    display: none !important;
  }
  .design-2-actions-grid {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .design-2-header {
    padding-top: 20px;
  }
  .shc-view img {
    height: 250px;
  }
  .p2-actions {
    max-width: 530px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Started From Here */
  .p2-profile-bar-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .project-action-btn .icon {
    font-size: 30px;
  }
  .project-action-btn .title {
    font-size: 15px;
  }
  .p2-place-grid {
    grid-template-columns: repeat(6, 1fr);
  }
  .d2-image-view {
    height: 125px;
  }
  .p2-avatar img {
    width: 135px;
  }
  .p2-slider-left {
    height: 335px;
  }
  .d2-image-view {
    max-width: calc(33% - 1px);
    flex-basis: calc(33% - 1px);
  }
  .p2-actions {
    max-width: 710px;
    left: 50%;
    transform: translateX(-50%);
  }
  /* Started From Here */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .p2-place-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
