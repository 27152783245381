/* Global */
* {
  font-family: "Arabic Dubai" !important;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

body,
html {
  height: 100%;
  /* scroll-behavior: smooth; */
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Dubai Font */
@font-face {
  font-family: "Arabic Dubai";
  src: url("../fonts/dubai_fonts/DubaiW23-Regular.eot") format("eot");
  src: url("../fonts/dubai_fonts/DubaiW23-Regular.ttf") format("ttf");
  src: url("../fonts/dubai_fonts/DubaiW23-Regular.woff") format("woff");
  src: url("../fonts/dubai_fonts/DubaiW23-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Arabic Dubai";
  src: url("../fonts/dubai_fonts/DubaiW23-Medium.eot") format("eot");
  src: url("../fonts/dubai_fonts/DubaiW23-Medium.ttf") format("ttf");
  src: url("../fonts/dubai_fonts/DubaiW23-Medium.woff") format("woff");
  src: url("../fonts/dubai_fonts/DubaiW23-Medium.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Arabic Dubai";
  src: url("../fonts/dubai_fonts/DubaiW23-Bold.eot") format("eot");
  src: url("../fonts/dubai_fonts/DubaiW23-Bold.ttf") format("ttf");
  src: url("../fonts/dubai_fonts/DubaiW23-Bold.woff") format("woff");
  src: url("../fonts/dubai_fonts/DubaiW23-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Arabic Dubai";
  src: url("../fonts/dubai_fonts/DubaiW23-Light.eot") format("eot");
  src: url("../fonts/dubai_fonts/DubaiW23-Light.ttf") format("ttf");
  src: url("../fonts/dubai_fonts/DubaiW23-Light.woff") format("woff");
  src: url("../fonts/dubai_fonts/DubaiW23-Light.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}
.ne_font {
  font-family: "Arial" !important;
}

.fas,
.fal,
.far,
.fab {
  font-family: "Font Awesome 5 Brands" !important;
}

input,
button {
  outline: none !important;
  border: none;
  background: none;
}

button {
  cursor: pointer;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none !important;
  cursor: pointer;
  color: #000;
}

ul {
  margin-bottom: 0;
}

.btn,
nav,
.icon-arrow-right:before,
.opacity-hover,
.button-default,
.mbg-card,
.dl-btn,
.ms-icon,
.ms-link,
.project-action-btn,
.selected-title .icon {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.gradient-easing {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.00709) 10.47%,
    rgba(0, 0, 0, 0.02755) 19.56%,
    rgba(0, 0, 0, 0.06016) 27.58%,
    rgba(0, 0, 0, 0.1037) 34.81%,
    rgba(0, 0, 0, 0.15697) 41.57%,
    rgba(0, 0, 0, 0.21875) 48.13%,
    rgba(0, 0, 0, 0.28782) 54.79%,
    rgba(0, 0, 0, 0.36296) 61.85%,
    rgba(0, 0, 0, 0.44297) 69.61%,
    rgba(0, 0, 0, 0.52662) 78.36%,
    rgba(0, 0, 0, 0.6127) 88.39%,
    rgba(0, 0, 0, 0.7)
  );
  bottom: 0;
  right: 0;
  left: 0;
}
.width-fluid {
  width: 100%;
}
.height-fluid {
  height: 100%;
}
.white-color {
  color: #ffffff !important;
}
.black-color {
  color: #000000 !important;
}
.main-color {
  color: #3b79a7;
}
.gray-color {
  color: #898989;
}
.form-group {
  border: 1px solid #000;
}
.icon-search {
  display: inline-block;
  margin-right: 7px;
}
.form-control {
  display: block;
  color: #666666;
  font-size: 18px;
  background: none;
  font-weight: 400;
  padding: 10px;
  height: auto;
  border-radius: 0;
  border: none;
}
.form-control::placeholder {
  color: #666666;
  font-size: 16px;
  font-weight: 500;
}
.form-control:hover {
  outline: none;
}
.form-control:active {
  outline: none;
}
.form-control:focus {
  outline: none;
  background: none;
  border: none;
}

input[type="file"] {
  opacity: 0;
  z-index: 20;
  top: 0;
  left: 0;
}

.circle {
  border-radius: 100% !important;
}
.opacity-hover:hover {
  opacity: 0.7;
}
.opacity-hover:active {
  opacity: 0.4;
}
.title {
  font-weight: 600;
  font-size: 27px;
}
.text {
  font-size: 16px;
  line-height: 1.3;
}
.container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}
.overlay {
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
}
small {
  color: #333;
  font-weight: 600;
}

.bg-title {
  color: #000;
  font-weight: bold;
  line-height: 1;
  font-size: 37px;
}
select {
  border: none !important;
  background: none;
  width: 100% !important;
}

/** Buttons **/
.btn {
  border-radius: 4px;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}
.btn:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}
.nl-btn {
  font-size: 20px;
}
.nl-btn:hover {
  opacity: 0.7;
}
.nl-btn:active {
  opacity: 0.4;
}
.nl-btn .icon {
  font-weight: bold;
  margin: 0 8px;
}
.button-default {
  display: inline-block;
}
.button-default > * {
  line-height: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
}
.button-default .icon {
  font-weight: 600;
  margin-left: 9px;
}
.bt-black > *,
.bt-black > *:focus {
  background: #000;
  color: #fff;
}
.bt-black > *:hover {
  background: #303030;
  color: #fff;
}
.bt-black > *:active {
  opacity: 0.6;
  background: #303030;
  color: #fff;
}

.bt-white > *,
.bt-white > *:focus {
  background: #fff;
  color: #000;
}
.bt-white > *:hover {
  background: #dbdada;
  color: #303030;
}
.bt-white > *:active {
  opacity: 0.6;
  background: #fff;
  color: #303030;
}
.read-more-btn {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  line-height: 1.2;
}
.read-more-btn:hover {
  opacity: 0.7;
}
.read-more-btn:active {
  opacity: 0.4;
}
/** Buttons **/

/* Range Slider */
.irs-min,
.irs-max,
.irs-from,
.irs-to,
.irs-single,
.irs-handle i {
  display: none !important;
}
.irs--flat .irs-line,
.irs--flat .irs-bar {
  height: 3px;
}
.irs--flat .irs-handle {
  background: #fff;
  border: 2px solid #000;
  border-radius: 100%;
  top: 13px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  box-shadow: 0 3px 6px #4d4d4d50;
}
.irs--flat .irs-line,
.irs-bar {
  background-color: #000 !important;
}
/* Range Slider */

/* Scroller Bar */
.modal-tab-overview::-webkit-scrollbar,
.ss-list::-webkit-scrollbar {
  width: 11px;
}

/* Track */
.ss-list::-webkit-scrollbar-track,
.modal-tab-overview::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.ss-list::-webkit-scrollbar-thumb,
.modal-tab-overview::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 50px;
}

/* Handle on hover */
.ss-list::-webkit-scrollbar-thumb:hover,
.modal-tab-overview::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
/* Scroller Bar */

/* Checkbox */
.fake-checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
}
.input-checkbox {
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 13;
  cursor: pointer;
}
.input-checkbox:checked + .fake-cb-child > span {
  opacity: 1;
}
.fake-cb-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 3px;
  border: 1px solid #333;
}
.fake-cb-child > span {
  display: block;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
}
.checkbox-text {
  margin: 0 9px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  width: calc(100% - 40px);
}
.checkbox-toggle-btn > div {
  font-size: 16px;
  font-weight: 600;
  color: #626262;
}
.checkbox-toggle-btn .icon {
  font-size: 12px;
  font-weight: bold;
  color: #000;
}
/* Checkbox */

/* Selectz */
.filter-slider-bar > div {
  width: 25%;
  padding: 15px 20px;
  background: #fff;
}
.filter-slider-bar .selectbox-slim {
  border-right: 1px solid #ccc;
}

.selectbox-slim .ss-main,
.selectbox-slim .ss-single-selected {
  /* height: 100%; */
  padding: 0;
  border: none;
}

.selectbox-slim .ss-content {
  width: calc(100% + 40px);
  left: -20px;
  top: calc(100% + 21px);
  border: none;
  box-shadow: 0 1px 2px #4d4d4d50;
}

.ss-option {
  border-bottom: 1px solid #aaa;
  padding: 8px 20px !important;
  font-size: 17px;
  color: #000;
  font-weight: 600;
}

.ss-main .ss-single-selected {
  height: auto !important;
}

.ss-content .ss-list .ss-option.ss-highlighted,
.ss-content .ss-list .ss-option:hover {
  background: #000;
  color: #fff;
}

.ss-content .ss-list .ss-option.ss-disabled:hover {
  background: none;
  color: #dedede;
}

.ss-option:last-of-type {
  border: none;
}

.ss-main .ss-single-selected .ss-arrow span {
  border-width: 0 3px 3px 0;
  padding: 3px;
}

.ss-main .placeholder {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.ss-label {
  line-height: 1;
  margin-bottom: 2px;
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 600;
  opacity: 0.8;
  width: 100%;
}
.ss-content .ss-list {
  max-height: 125.39px;
}

/* Contact */
.selectbox-tel-keys .placeholder {
  font-size: 16px;
}
.selectbox-tel-keys .ss-main .ss-single-selected .ss-arrow span {
  border-width: 0 2px 2px 0;
  padding: 3px;
}
.selectbox-tel-keys .ss-content {
  width: 70px;
  left: -8px;
  top: calc(100% + 17px);
}
.selectbox-tel-keys .ss-option {
  padding: 8px 12px !important;
  text-align: center !important;
  font-size: 15px;
}
/* Contact */

/* Selectz */

/* Modal */
.modal {
  z-index: 105000;
}
/* Modal */
/* Global */

/* Navbar */
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  padding: 11px 0;
}
nav.active,
nav.active-page {
  background: #000 !important;
  box-shadow: 0px 2px 2px #00000017;
}
.navbar {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-logo {
  width: 230px;
}
.nav-icon-btn {
  cursor: pointer;
  padding: 0 13px;
  display: flex;
}
.nav-icon-btn:last-of-type {
  padding-right: 0;
}
.nav-icon-btn:hover {
  opacity: 1;
}
.nav-icon-btn:active {
  opacity: 0.4;
}
.nav-icon-btn .icon {
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
}
.nav-icon-btn .icon.icon-cancel {
  font-weight: bold;
}
.menu-bar {
  display: flex;
  justify-content: space-between;
  height: 27px;
  width: 22px;
}
.menu-bar span {
  width: 2.2px;
  background: #fff;
}
.nav-icon-btn .label {
  color: #fff !important;
  font-size: 15px !important;
  margin-right: 22px !important;
}
.nav-icon-btn .select-chevron-div .icon {
  font-size: 12px;
}
.nav-icon-btn .select-chevron-div {
  right: 2px;
}
.tel-dropdown {
  background: #fff;
  position: relative;
  padding: 10px;
  display: none;
  margin-left: 10px;
  line-height: 1;
  letter-spacing: 2px;
  font-weight: 600;
}
.nav-icon-btn:hover .tel-dropdown {
  display: block;
}
.nav-btn-select:hover,
.nav-btn-select:active {
  opacity: 1;
}
.selectbox-language .ss-single-selected {
  background: none;
  padding: 0;
}
.selectbox-language .placeholder {
  font-size: 15px;
  color: #fff;
}
.selectbox-language .ss-main .ss-single-selected .ss-arrow span {
  border: solid #fff;
  border-width: 0 2px 2px 0;
}
.selectbox-language .ss-option {
  padding: 5px 10px !important;
  font-size: 14px;
  text-align: center !important;
}
.selectbox-language .ss-content {
  width: 100%;
  left: auto;
}
/* Navbar */

/* Menu */
.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101000;
  background: #fff;
  display: none;
}
.menu-top nav {
  position: relative;
}
.menu-body {
  padding-top: 80px;
  padding-bottom: 40px;
  overflow-y: auto;
}
.ms-link {
  width: 100%;
  padding: 16px 0;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  margin-bottom: 27px;
}
.ms-link:hover {
  /* opacity: 0.4; */
}
.ms-link:hover .ms-icon {
  transform: translateX(21px);
}
.ms-text {
  width: 100%;
}
.menu-sidebar {
  width: 29%;
}
.ms-text .ms-sub-title {
  line-height: 1;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
  color: #666;
}
.ms-text .title {
  font-size: 30px;
  line-height: 1;
}
.ms-icon .icon {
  font-weight: bold;
  font-size: 32px;
}
.menu-bottom .footer-bottom {
  border-top: 1px solid #000;
}
.menu-bottom .footer-b-links a {
  color: #000;
  font-weight: 600;
}
.menu-bottom .text {
  font-weight: 600;
}
.ms-icon {
  margin-left: 12px;
}
.menu-body-cards {
  width: 67%;
}
.menu-body-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 30px;
}
.mbg-card {
  width: 100%;
  border: 2px solid #707070;
  padding: 21px 30px;
  font-size: 28px;
}
.mbg-card .icon {
  font-size: 41px;
}
.mbg-card:hover {
  background: #000;
  color: #fff;
  border: 2px solid #000;
}
.mbg-card:active {
  opacity: 0.8;
}
.mbg-title {
  line-height: 1;
  padding-left: 13px;
  padding-top: 4px;
  font-size: 38px;
}
.menu-social a {
  margin-left: 15px;
  font-size: 18px;
}
/* Menu */

/* Home */
.home-swiper-container {
  height: 100vh;
  min-height: 680px;
}
.slide-home-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  position: relative;
}
.slide-home-video {
  height: 100%;
}
.slide-home-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-arrows {
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.swiper-arrows {
  width: 100%;
}
.arrow-icon {
  display: flex;
  width: 100%;
  transform: rotateZ(-90deg);
  margin: 10px 0;
}
.arrow-icon:hover {
  opacity: 0.7;
}
.arrow-icon:active {
  opacity: 0.4;
}
.arrow-icon .icon {
  color: #fff;
  font-size: 24px;
}
.swiper-pagination-arrows .swiper-pagination {
  position: relative !important;
}
.swiper-pagination-bullet {
  display: block;
  margin: 5px 0 !important;
  width: 3px;
  height: 36px;
  border-radius: 0;
  position: static;
}
.swiper-pagination-bullet-active {
  background: #fff;
}
.search-filter {
  background: rgba(0, 0, 0, 0.48);
  max-width: 100%;
  width: 930px;
  padding: 19px 27px 14px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}
.search-filter-header {
  color: #fff;
  line-height: 1;
  font-size: 32px;
  margin-bottom: 13px;
}
.search-filter-dropdowns {
  padding: 14px 18px;
  background: #fff;
}
.search-filter-advanced {
  text-align: right;
  padding-top: 14px;
}
.advanced-search-link {
  line-height: 1;
  color: #fff;
  font-size: 19px;
}
.advanced-search-link:hover,
.advanced-search-link:active {
  color: #fff;
}
.advanced-search-link .icon {
  font-size: 22px;
}
.asl-text {
  padding: 0 8px;
}
.home-grid-double {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 40px;
  padding-top: 100px;
  padding-bottom: 70px;
}
.home-grid-card {
  position: relative;
}
.home-grid-card img {
  height: 383px;
  width: 100%;
  object-fit: cover;
}
.home-grid-card-data {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 25px 35px;
}
.home-grid-card-data .bg-title {
  color: #fff;
  line-height: 1;
  font-size: 34px;
  cursor: pointer;
}
.home-grid-card-data .bg-title .icon {
  display: inline-block;
  font-size: 30px;
  margin-left: 15px;
}
.projects-container {
  padding-bottom: 50px;
}
.projects-grid {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 30px;
}
.projects-title {
  font-size: 27px;
  margin-bottom: 36px;
}
.project-card {
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-card img {
  height: 177px;
  padding: 16px;
  max-width: 100%;
}
.projects-grid-button {
  padding: 40px 0;
}
.sa-grid {
  display: grid;
  grid-template-columns: 2.1fr 1fr;
  grid-gap: 12.52rem;
  gap: 30px;
}
.services-about-container {
  padding-bottom: 80px;
}
.sa-card {
  padding: 30px;
}
.service-card {
  border: 1px solid #00000036;
  background: #f8f9fa;
  color: #838383;
  display: flex;
}
.service-card .sa-data {
  width: 100%;
  padding-right: 25px;
}
.sa-data {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 100%;
}
.service-card .sa-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sa-sub-title {
  margin-bottom: 15px;
}
.sub-title-vline {
  width: 70px;
  height: 2px;
  background: #00000036;
  margin: 0 8px;
}
.about-card {
  background-color: #000;
}
.about-card .sa-sub-title {
  color: #d6d6d6;
}
.about-card .sub-title-vline {
  background-color: #d6d6d6;
}
.sa-card .bg-title {
  line-height: 1;
  font-size: 29px;
}
/* Home */

/* Search Filter */
.search-filter-selectbox {
  /* Remove It */
  padding-right: 15px;
  border-right: 1px solid #626262;
  line-height: 1;
}
.search-filter-selectbox:last-of-type {
  /* Remove It */
  padding: 0 !important;
  border: none !important;
}
#modalSearchFilter .modal-dialog-centered {
  height: calc(100% - 6rem) !important;
  min-height: auto !important;
  margin: 3rem auto;
}
#modalSearchFilter .modal-content {
  height: 100%;
}
#modalSearchFilter .modal-body {
  padding: 0;
  border-radius: 0;
  height: 100%;
}
.modal-dialog {
  max-width: 600px;
}
.modal-content {
  border: none;
  border-radius: 1px;
}
.filter-title .icon {
  font-size: 27px;
}
.close-filter .icon {
  font-size: 25px;
  font-weight: bold;
}
.modal-s-top,
.modal-s-bottom {
  padding: 25px 30px;
}
.modal-filter-grid-double {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 30px;
}
.filter-price-range {
  margin-top: 32px;
}
.filter-price-range .title {
  font-size: 20px;
  line-height: 1;
}
.modal-f-label {
  font-weight: 600;
  margin: 0;
  font-size: 16px;
  color: #a5a5a5;
  display: block;
  margin-bottom: 4px;
}
.modal-filter-dropdown .ss-label {
  margin-bottom: 5px;
}
.modal-filter-dropdown {
  border-bottom: 1px solid #656565;
  padding-bottom: 10px;
}
.modal-filter-dropdown .ss-content {
  width: calc(100%);
  left: 0;
  top: calc(100% + 16px);
  border: none;
}
.mfgd-input {
  display: flex;
  border: 1px solid #797979;
  padding: 9px 18px;
  font-size: 19px;
  margin-top: 20px;
}
.modal-s-center {
  padding: 25px 30px;
  overflow-y: auto;
  border-bottom: 1px solid #d1d1d1;
  height: calc(100% - 187px);
}
.modal-s-bottom .button-default > * {
  padding: 13px 20px;
  font-size: 19px;
}
.cancel-btn {
  border: 1px solid #0d0d0d;
}
.mcb-title {
  font-size: 22px;
  line-height: 1;
  font-weight: 600;
  cursor: pointer;
}
.mcb-title .icon {
  font-weight: bold;
  font-size: 18px;
  color: #000;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.multiple-checkbox-container {
  display: none;
  margin-bottom: 35px;
}
.multiple-checkbox-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 23px;
  padding-top: 23px;
  padding-left: 23px;
}
.modal-checkbox-row {
  margin-bottom: 20px;
}
.modal-checkbox-container {
  display: none;
}
/* Search Filter */

/* Footer */
footer {
  background: #000000;
}
.footer-top {
  padding-top: 50px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1.3fr 0.9fr 0.9fr 0.9fr 1.6fr;
  grid-gap: 12.52rem;
  gap: 32px;
}
.footer-title {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 14px;
  text-transform: uppercase;
}
.footer-link {
  margin-bottom: 15px;
  line-height: 1;
}
.footer-link a {
  color: #fff;
  font-size: 18px;
}
.footer-link a:hover {
  opacity: 0.7;
}
.footer-link a:active {
  opacity: 0.4;
}
.footer-contact-group {
  border: 1px solid #fff;
  display: flex;
}
.footer-contact-group input {
  padding: 7px 15px;
  color: #fff;
  font-weight: 600;
  border-right: 1px solid #fff;
  width: 100%;
  border-radius: 0 !important;
}
.footer-contact-group .icon {
  font-size: 14px;
}
.footer-contact-group .button-default > * {
  font-size: 16px;
}
.follow-line {
  min-width: 25%;
  height: 2px;
  background: #ffffff;
}
.social-icons a {
  color: #fff;
  margin-left: 10px;
  font-size: 21px;
}
.social-icons a:hover {
  opacity: 0.7;
}
.social-icons a:active {
  opacity: 0.4;
}
.social-icons a:first-of-type {
  margin: 0;
}
.footer-bottom {
  padding: 16px 0;
  border-top: 1px solid #fff;
}
.footer-b-links a {
  color: #fff;
  margin-left: 15px;
}
.footer-b-links a:hover {
  opacity: 0.7;
}
.footer-b-links a:active {
  opacity: 0.4;
}
/* Footer */

/* Projects Slider */
.projects-slider {
  padding-top: 30px;
  padding-bottom: 120px;
}
.projects-slider-header {
  border-top: 1px solid #000;
  padding-top: 9px;
  margin-bottom: 28px;
}
.ps-arrows button {
  margin-left: 15px;
}
.ps-arrows .icon {
  font-weight: bold;
  font-size: 25px;
}
.product-card-view {
  width: 100%;
  height: 177px;
  object-fit: cover;
  margin-bottom: 20px;
}
.product-card-title {
  font-size: 18px;
  font-weight: 600;
  color: #00153c;
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: 9px;
  margin-bottom: 14px;
  line-height: 1;
}
.product-card-company {
  display: flex;
}
.pcc-logo img {
  width: 65px;
  height: 65px;
}
.pcc-data {
  padding: 10px 15px;
}
.pcc-title {
  line-height: 1;
  color: #838383;
  font-size: 17px;
  margin-bottom: 10px;
}
.pcc-price {
  line-height: 1;
  font-size: 19px;
  font-weight: 600;
}
/* Projects Slider */

/* Search No Results */
#modalSearchFilter .search-no-results {
  height: calc(100% - 187px);
}
.search-no-results {
  background: #fff;
  padding: 15px 23px;
}
.snr-title {
  margin-bottom: 5px;
}
.snr-title img {
  width: 27px;
}
.snr-title span {
  font-weight: bold;
  font-size: 20px;
  margin: 0 10px;
  color: #000;
}
.snr-text {
  font-size: 17px;
}
.snr-dropdown {
  background: #eee;
  padding: 15px;
}
.main-adv-search {
  display: none;
}
.main-adv-search.active {
  display: flex !important;
}
/* Search No Results */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  /* Home */
  .swiper-pagination-arrows {
    display: none;
  }
  /* Home */

  /* Footer */
  .footer-contact-group .button-default > * {
    font-size: 14px;
    padding: 10px 13px;
  }
  /* Footer */

  /* Filter Search */
  .modal-filter-grid-double {
    gap: 10px;
  }
  .mfgd-input {
    padding: 9px 9px;
    font-size: 17px;
    justify-content: center;
    font-weight: 600;
  }
  .mfgd-input-val {
    font-weight: 600;
  }
  .multiple-checkbox-grid {
    padding-left: 11px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .checkbox-text {
    font-size: 18px;
  }
  .modal-s-bottom .modal-filter-grid-double {
    display: flex;
    justify-content: space-between;
  }
  .modal-s-bottom .modal-filter-grid-double > div {
    width: 48%;
  }
  #modalSearchFilter .modal-dialog-centered {
    height: calc(100% - 1rem) !important;
    margin: 0.5rem;
  }
  .modal-f-label {
    font-size: 15px;
  }
  .modal-filter-dropdown .selected-title .title,
  .mcb-title {
    font-size: 18px;
  }
  .mcb-title .icon,
  .modal-filter-dropdown .icon-chevron-rotate {
    font-size: 13px;
  }
  /* Filter Search */
}

@media (max-width: 767.98px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .search-filter-action .button-default > * {
    padding: 15px 20px;
  }

  /* Navbar */
  nav {
    padding: 15px 0;
  }
  .nav-logo {
    width: 164px;
  }
  .nav-icon-btn {
    padding: 0 6px;
    position: relative;
  }
  .nav-icon-btn:hover .tel-dropdown {
    display: block !important;
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
  .nav-icon-btn .icon {
    font-size: 20px;
  }
  .menu-bar {
    height: 25px;
    width: 21px;
  }
  /* Navbar */

  /* Menu */
  .menu-wrapper {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
  .menu-body-row {
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .menu-body-row > div {
    width: 100%;
  }
  .menu-body {
    padding-top: 13px;
  }
  .menu-body-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 15px;
    margin-bottom: 25px;
  }
  .mbg-card {
    padding: 17px 19px;
  }
  .mbg-title {
    font-size: 28px;
  }
  .mbg-card .icon {
    font-size: 29px;
  }
  .menu-bottom .footer-b-links,
  .menu-socialmedia .text {
    display: none !important;
  }
  .menu-bottom .footer-bottom .text {
    display: block;
    font-size: 14px;
  }
  .menu-social a {
    margin-left: 7px;
    font-size: 14px;
  }
  .ms-text .ms-sub-title {
    font-size: 14px;
  }
  .ms-text .title {
    font-size: 24px;
  }
  .ms-link {
    margin-bottom: 16px;
  }
  /* Menu */

  /* Search Filter */
  .search-filter {
    max-width: 400px;
    width: calc(100% - 70px);
    padding: 19px 20px 14px;
  }
  .search-filter-header {
    font-size: 23px;
  }
  .search-filter-selectbox {
    border: none;
    background: #fff;
    padding: 9px 15px;
    margin-bottom: 10px;
  }
  .search-filter-advanced {
    text-align: center;
  }
  .filter-slider-bar {
    flex-wrap: wrap;
  }
  .filter-slider-bar > div {
    margin-bottom: 8px;
    width: 100%;
    padding: 10px 15px;
  }
  .filter-bar-action {
    padding: 0 !important;
    background: none !important;
  }
  .filter-slider-bar .selectbox-slim .ss-content {
    width: calc(100% + 30px);
    left: -15px;
    top: calc(100% + 17px);
  }
  /* Search Filter */

  /* Home */
  .home-grid-double {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-top: 50px;
  }
  .home-grid-card-data {
    padding: 25px 25px;
  }
  .home-grid-card-data .bg-title {
    line-height: 1.1;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home-grid-card-data .bg-title .icon {
    left: auto;
    position: static;
  }
  /* Home */

  /* Projects Grid */
  .projects-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 12px;
  }
  .project-card img {
    height: auto;
    padding: 7px;
  }
  /* Projects Grid */

  /* Services & About */
  .sa-grid {
    grid-template-columns: 1fr;
    padding: 0;
  }
  .service-card {
    flex-direction: column-reverse;
  }
  .sa-card {
    padding: 22px;
  }
  .sa-img {
    margin-bottom: 22px;
  }
  .sa-card .bg-title {
    line-height: 1.2;
    font-size: 23px;
  }
  /* Services & About */

  /* Footer */
  .footer-links {
    display: none;
  }
  .footer-logo {
    display: none;
  }
  .footer-top {
    grid-template-columns: 1fr;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .footer-bottom .text {
    display: none;
  }
  .footer-b-links a {
    margin-left: 0;
    margin-right: 15px;
  }
  /* Footer */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 540px;
  }

  /* Home */
  .swiper-pagination-arrows {
    right: 12px;
  }
  /* Home */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 720px;
  }

  /* Filter */
  .search-filter {
    width: 690px;
  }
  .search-filter-selectbox {
    padding-right: 4px;
  }
  .swiper-pagination-arrows {
    display: none;
  }
  .filter-slider-bar {
    flex-wrap: wrap;
  }
  .filter-slider-bar > div {
    padding: 11px 13px;
    width: 50%;
  }
  .filter-slider-bar .selectbox-slim {
    border-bottom: 1px solid #ccc;
  }
  .filter-slider-bar .selectbox-slim .ss-content {
    width: calc(100% + 26px);
    left: -13px;
    top: calc(100% + 17px);
  }
  .selected-title .title {
    font-size: 17px;
  }
  .selectz-label {
    font-size: 13px;
  }
  .selected-title .icon {
    font-size: 12px;
  }
  /* Filter */

  /* Home */
  .home-grid-double {
    padding-top: 70px;
    gap: 20px;
  }
  .home-grid-card-data .bg-title .icon {
    left: auto;
    margin-left: 6px;
  }
  .home-grid-card-data .bg-title {
    line-height: 1.2;
    font-size: 28px;
  }
  .sa-grid {
    grid-template-columns: 1fr 1fr;
  }
  .service-card .sa-data {
    padding: 0;
  }
  .sa-img {
    display: none;
  }
  /* Home */

  /* Projects */
  .projects-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
  }
  .project-card img {
    height: auto;
  }
  /* Projects */

  /* Footer */
  .footer-top {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-logo img {
    width: 236px;
  }
  /* Footer */

  /* Menu */
  .menu-body-grid {
    gap: 18px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .mbg-card .icon {
    font-size: 34px;
  }
  .mbg-title {
    font-size: 29px;
  }
  .mbg-card {
    padding: 21px 21px;
  }
  .menu-wrapper .footer-b-links {
    display: none;
  }
  .menu-body {
    padding-top: 20px;
  }
  .menu-body-row > div {
    width: 47%;
  }
  .ms-text .title {
    font-size: 26px;
  }
  /* Menu */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 960px;
  }
  /* Home */
  .swiper-pagination-arrows {
    display: none;
  }
  .home-grid-card-data .bg-title .icon {
    position: static;
    margin-top: 5px;
  }
  .home-grid-card-data .bg-title {
    font-size: 31px;
  }
  .home-grid-double {
    padding-top: 70px;
  }
  .sa-img {
    width: 100%;
  }
  .sa-card .bg-title {
    line-height: 1.2;
  }
  /* Home */

  /* Filter */
  .search-filter {
    width: 920px;
  }
  /* Filter */

  /* Projects */
  .projects-grid {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 20px;
  }
  .project-card img {
    height: auto;
  }
  /* Projects */

  /* Footer */
  .footer-top {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-logo img {
    width: 236px;
  }
  /* Footer */

  /* Menu */
  .menu-body {
    padding-top: 20px;
  }
  .mbg-card {
    padding: 20px 20px;
  }
  .mbg-card .icon {
    font-size: 35px;
  }
  .mbg-title {
    font-size: 27px;
  }
  .menu-body-grid {
    gap: 20px;
  }
  .ms-text .ms-sub-title {
    font-size: 14px;
  }
  .ms-text .title {
    font-size: 26px;
  }
  .ms-link {
    margin-bottom: 18px;
  }
  /* Menu */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .container-fluid {
    max-width: 1140px;
  }

  /* Projects */
  .projects-grid {
    gap: 20px;
  }
  .project-card img {
    height: auto;
  }
  /* Projects */

  /* Menu */
  .mbg-title {
    font-size: 31px;
  }
  /* Menu */

  /* Footer */
  .footer-top {
    gap: 16px;
  }
  .follow-line {
    min-width: 16%;
  }
  /* Footer */
}

@media (min-width: 1400px) {
  .container-fluid {
    max-width: 1400px;
  }
}
