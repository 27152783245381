.careers-header {
  background-image: url("https://media.cdn.teamtailor.com/images/s3/teamtailor-production/hero_picture_large_full_cover-v4/image_uploads/c64339a1-d46f-491e-84fe-685422b3bf42/original.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}
.careers {
  background: #f9f7f6;
}
.careers-data {
  padding-top: 60px;
  padding-bottom: 40px;
  position: relative;
  z-index: 10;
}
.careers-data .bg-title,
.career-cards .bg-title {
  font-size: 60px;
  font-weight: 500;
  line-height: 1.25;
  word-wrap: break-word;
  text-align: center;
  color: #fff;
  font-family: "Playfair Display" !important;
  margin-bottom: 25px;
}
.career-btn {
  text-transform: uppercase;
  border-radius: 50px;
  padding: 12px 50px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2px;
  margin: 0 25px;
  min-width: 220px;
  display: inline-block;
}
.career-btn:hover {
  background: #393939;
  color: #fff;
}
.career-btn:active {
  opacity: 0.7;
  color: #fff;
}
.career-btn:focus {
  color: #fff;
}
.career-down {
  border: 4px solid #fff;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  display: flex;
}
.career-down .icon {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}
.careers-st-container {
  max-width: 660px;
  margin: auto;
}
.careers-st-container {
  padding-top: 80px;
  padding-bottom: 80px;
}
.careers-socialmedia {
  padding-bottom: 80px;
}
.careers-socialmedia .social-icon {
  font-size: 17px;
  padding: 9px 20px;
  line-height: 1;
  display: inline-block;
  border-radius: 4px;
  color: #fff;
  margin: 0 10px;
  font-weight: 600;
}
.careers-socialmedia .social-icon i {
  margin-top: 2px;
  margin-right: 5px;
}
.social-icon.linkedin {
  background: #007bb6;
}
.social-icon.facebook {
  background: #3b5998;
}
.social-icon.youtube {
  background: #ff0000;
}
.social-icon.instagram {
  background: #8a3ab9;
}
.social-icon.mail {
  background: #4e4e4e;
}

.careers-text .bg-title {
  font-size: 47px;
  font-weight: 500;
  line-height: 1.25;
  word-wrap: break-word;
  text-align: center;
  font-family: "Playfair Display" !important;
  margin-bottom: 15px;
}
.careers-text .text {
  font-size: 21px;
  color: #333333;
  font-weight: 500;
  line-height: 1.6;
}
.career-cards {
  padding-top: 80px;
  padding-bottom: 80px;
}
.careers-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 12.52rem;
  gap: 20px;
}
.career-card {
  width: 100%;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 9%), 0 2px 12px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 0 0 1px rgb(0 0 0 / 9%), 0 2px 12px 0 rgb(0 0 0 / 5%);
  display: inline-block;
  height: auto;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  cursor: pointer;
}
.career-card-img {
  height: 210px;
  width: 100%;
  object-fit: cover;
}
.career-card-data {
  display: flex;
  justify-content: center;
  place-items: center;
  text-align: center;
  padding: 50px 10px;
}
.career-card-data-position {
  color: #c18e60;
  font-size: 24px;
}
.career-card-data-desc {
  color: #333333;
  font-size: 19px;
}
.career-card:hover {
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 2px 18px 0 rgb(0 0 0 / 20%);
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}
.boldly-title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
}
.career-list li {
  font-size: 20px;
  color: #333333;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 12px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .careers-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 767.98px) {
  .careers-data .bg-title,
  .career-cards .bg-title {
    font-size: 35px;
  }
  .careers-action .career-btn {
    margin-bottom: 21px;
  }
  .career-btn {
    font-size: 14px;
    margin: 0 17px;
  }
  .career-down {
    width: 45px;
    height: 45px;
  }
  .career-down .icon {
    font-size: 14px;
  }
  .careers-data {
    padding: 60px 20px 40px;
  }
  .social-icon span {
    display: none;
  }
  .careers-socialmedia {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .careers-socialmedia .social-icon i {
    margin: 0;
  }
  .careers-socialmedia .social-icon {
    padding: 0;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  .careers-text .bg-title {
    font-size: 30px;
  }
  .careers-text .text {
    font-size: 17px;
  }
  .career-list li {
    font-size: 17px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .career-btn {
    padding: 12px 24px;
    min-width: 196px;
    margin: 0 15px;
  }
  .careers-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .career-card-data-position {
    font-size: 20px;
  }
  .career-card-data-desc {
    font-size: 18px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .careers-data {
    padding: 60px 30px 50px;
  }
  .careers-data .bg-title,
  .career-cards .bg-title {
    font-size: 45px;
  }
  .career-btn {
    min-width: 216px;
    padding: 12px 42px;
  }
  .careers-text .bg-title {
    font-size: 42px;
  }
  .careers-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .careers-data .bg-title,
  .career-cards .bg-title {
    font-size: 55px;
  }
  .careers-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .careers-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/* Extra large devices (large desktops, 1400px and up) */
@media (min-width: 1400px) {
}
