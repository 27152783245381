.contact-header img {
  width: 100%;
  object-fit: cover;
}
.contact-container {
  padding: 70px 0 150px;
}
.contact-data {
  width: 60%;
}
.contact-form {
  width: 37%;
  padding-top: 35px;
}
.contact-text-container {
  margin-bottom: 100px;
}
.sub-title {
  font-size: 22px;
  margin-bottom: 4px;
}
.bg-title {
  font-size: 40px;
  margin-bottom: 30px;
}
.contact-text-container .text,
.contact-text-address-tel .text {
  font-size: 21px;
  color: #333;
  line-height: 1.5;
}
.contact-text-address-tel .text {
  color: #000;
}
.contact-text-address-tel > div:first-of-type {
  padding-right: 60px;
}
.contact-direction-btn .icon {
  font-weight: 600;
  font-size: 27px;
  margin: 0 17px;
}
.contact-direction-btn {
  font-size: 22px;
  color: #055bd0;
}
.contact-direction-btn:hover {
  color: #055bd0;
  opacity: 0.7;
}
.contact-direction-btn:active {
  color: #055bd0;
  opacity: 0.4;
}
.contact-direction-btn:focus {
  color: #055bd0;
}
.cform-title {
  font-weight: 600;
  font-size: 26px;
}
.cform-sub {
  font-size: 17px;
}
.cform-group {
  margin-bottom: 15px;
  border: 1px solid #898989;
  padding: 8px 17px;
}
.cform-group input,
.cform-group textarea {
  width: 100%;
  font-size: 18px;
  resize: none;
  outline: none;
  border-radius: 0 !important;
}
.cform-group textarea {
  border: none;
}
.cform-action .button-default > * {
  width: 100%;
  padding: 15px 26px;
  text-align: center;
  justify-content: center;
  font-size: 20px;
}
.cform-selecbox {
  margin-right: 13px;
  padding-right: 8px;
  border-right: 1px solid #898989;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .contact-header img {
    height: 370px;
  }
  .bg-title {
    font-size: 28px;
  }
  .sub-title {
    font-size: 19px;
  }
  .contact-text-container .text,
  .contact-text-address-tel .text {
    font-size: 19px;
  }
  .contact-direction-btn {
    font-size: 20px;
  }
  .contact-direction-btn .icon {
    font-size: 22px;
  }
  .contact-flexable {
    flex-wrap: wrap;
  }
  .contact-flexable > div {
    width: 100%;
  }
  .contact-text-address-tel {
    flex-wrap: wrap;
  }
  .contact-text-address-tel > div {
    margin-bottom: 13px;
  }
  .contact-text-address-tel > div:first-of-type {
    padding-right: 20px;
  }
  .contact-container {
    padding: 50px 0 150px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .contact-header img {
    height: 350px;
  }
  .contact-flexable {
    flex-wrap: wrap;
  }
  .contact-flexable > div {
    width: 100%;
  }
  .contact-form {
    padding-top: 50px;
  }
  .sub-title {
    font-size: 20px;
  }
  .bg-title {
    font-size: 31px;
  }
  .contact-direction-btn {
    font-size: 20px;
  }
  .contact-direction-btn .icon {
    font-size: 20px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .contact-data {
    width: 54%;
  }
  .contact-form {
    width: 42%;
    padding-top: 30px;
  }
  .bg-title {
    font-size: 30px;
  }
  .contact-text-address-tel > div:first-of-type {
    padding-right: 25px;
  }
  .contact-direction-btn {
    font-size: 19px;
  }
  .contact-direction-btn .icon {
    font-size: 21px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1399.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
