.swiper-pagination-bullet {
    display: block !important;
    margin: 5px 0 !important;
    width: 3px !important;
    height: 36px !important;
    border-radius: 0 !important;
    position: static !important;
  }
  .swiper-pagination-bullet-active {
    background: #fff !important;
  }